import React from "react";
import { WhyUsData } from "../../data/WhyUsData";
import whyUsPalm from "../../assets/whyUs/whyUsPalm.jpg";
import whyUs from "../../assets/whyUs/whyUs.png";

function WhyUs() {
  return (
    <div className="mt-16 px-10 md:px-16 space-y-10">
      <div className="py-10">
        <img src={whyUs} alt="" className="h-[100px] mx-auto" />
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 mt-[60px]">
        {WhyUsData.map((data, index) => {
          return (
            <div key={index} className="flex">
              <img src={data.image} alt="" className="w-[60px] h-[60px]  " />
              <div className="ml-4 md:ml-[60px] m/t-[-93px] gap-20">
                <h3 className="font-bold font-serif text-[17px]">
                  {data.title}
                </h3>
                <h4 className="mt-[10px]">{data.description}</h4>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col md:flex-row md:justify-center gap">
        <div className="flex flex-col justify-center bg-green-950 md:h-[450px] px-6 pb-6 md:px-10 md:w-[700px]">
          <h2 className="font-bold text-[28px] py-6 md:pt-[12/0px] text-purple-200 font-serif">
          African Oil Palm: Bridging Tradition and Modernity
            <br /> 
          </h2>
          <p className="text-slate-200 text-[17px]">
            African oil palm is more widely distributed than other types of palm
            trees and was first discovered in Africa along the coastal strip.
            Since being domesticated, the oil palm has been imported and grown
            throughout the humid tropics, with the majority of palm oil being
            used for food. It still has numerous traditional applications in
            Africa. Breeding can increase the amount of oil produced by the
            palm, enhance the type of oil it produces, and improve nutritional
            value.
          </p>
        </div>
        <div className="order-first md:order-last">
          <img
            src={whyUsPalm}
            alt=""
            className="w-full h-auto md:h-[450px] md:w-[450px] mt-[-4/50px] ml-[8/00px] "
          />
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
