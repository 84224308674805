import callIcon from '../assets/icons/callIcon.png'
import addressIcon from '../assets/icons/addressIcon.png'
import emailIcon from '../assets/icons/emailIcon.png'
 
export const contactIconData=[
    {
        title: 'Call Us',
        image : callIcon,
        description: '+234 807 160 0828'
    },
    {
        title: 'Address',
        image : addressIcon,
        description: 'MS2 Pent House, Block 4, Malberry Street, Micheville Estate Lokogoma, Abuja.'
    },
    {
        title: 'e-mail',
        image : emailIcon,
        description: 'help@oracleous.com'
    }
]