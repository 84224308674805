import React from "react";
import TopNav from "../components/general/TopNav";
import OurStoryHero from "../components/ourStory/OurStoryHero";
import Footer from "../components/general/Footer";

function OurStoryPage() {
 
  return (
    <div>
      <TopNav />
      <OurStoryHero />
      <Footer />
    </div>
  );
}

export default OurStoryPage;
