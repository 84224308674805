import React, { useState } from "react";
import OracleousLogo2 from "../../assets/logo/OracleousLogo.png";
import { Dialog, DialogPanel } from "@headlessui/react";
import closeIcon from "../../assets/icons/close.png";
import menuIcon from "../../assets/icons/menu.png";

function TopNav() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavOpen = () => {
    setIsNavOpen(!isNavOpen);
    console.log(isNavOpen);
  };
  return (
    <>
      <div className="Topnav w-full flex justify-between bg-white fixed top-0 z-20 h-[75px] shadow-md shadow-black">
        <div className="Logo">
          <img
            src={OracleousLogo2}
            alt="Logo"
            className="h-[65px] ml-[40px] mt-[5px] "
          />
        </div>

        <div className="Home hidden md:flex space-x-[50px] pt-[30px] text-lg text-black pr-10">
          <a href="/" className="hover:text-green-700 hover:underline">
            Home
          </a>
          <a
            href="/product-page"
            className="hover:text-green-700 hover:underline"
          >
            Products
          </a>
          <a
            href="/Our-Story-page"
            className="hover:text-green-700 hover:underline"
          >
            Our story
          </a>
          <a
            href="/about-us-page"
            className="hover:text-green-700 hover:underline"
          >
            About us
          </a>
          <a
            href="/contact-page"
            className="hover:text-green-700 hover:underline"
          >
            Contact
          </a>
        </div>
        <button className="block p-1 mr-10 w-10 md:hidden" onClick={handleNavOpen}>
          <img src={menuIcon} alt="" className="w-full" />
        </button>
      </div>

      {/* Mobile nav */}
      <Dialog as="div" open={isNavOpen} onClose={handleNavOpen}>
        <div className="fixed inset-0 z-50 bg-black/80" />
        <DialogPanel className="fixed inset-y-0 pl-10 right-0 z-50 w-4/5 md:w-[550px] bg-white">
          <div className="flex justify-between pr-10">
            <div />
            <button
              className="block p-1 w-10 mt-[30px]"
              onClick={handleNavOpen}
            >
              <img src={closeIcon} alt="" className="w-full" />
            </button>
          </div>
          <div className="flex flex-col gap-10 pt-[30px] text-4xl text-black">
            <a href="/" className="hover:text-green-700 hover:underline">
              Home
            </a>
            <a
              href="/product-page"
              className="hover:text-green-700 hover:underline"
            >
              Products
            </a>
            <a
              href="/Our-Story-page"
              className="hover:text-green-700 hover:underline"
            >
              Our story
            </a>
            <a
              href="/about-us-page"
              className="hover:text-green-700 hover:underline"
            >
              About us
            </a>
            <a
              href="/contact-page"
              className="hover:text-green-700 hover:underline"
            >
              Contact
            </a>
          </div>
        </DialogPanel>
      </Dialog>
    </>
  );
}

export default TopNav;
