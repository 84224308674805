import React from 'react'
import TopNav from '../components/general/TopNav'
import AboutUsHero from '../components/about-us/AboutUsHero'


function AboutUsPage() {
  
  return (
    <div>
      <TopNav/>
      <AboutUsHero/>
      
    </div>
    
  )
}

export default AboutUsPage