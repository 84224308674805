import Footer from "../components/general/Footer"
import TopNav from "../components/general/TopNav"
import HomeHero from "../components/homePage/HomeHero"
import OurExperiences from "../components/homePage/ourExperiences"
import WhyUs from "../components/homePage/WhyUs"


function HomePage() {
 
  return (
    <div>
        <TopNav/>
        <HomeHero/>
        <OurExperiences/>
        <WhyUs/>
        <Footer/> 
    </div>
  )
}

export default HomePage