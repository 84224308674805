import React from "react";
import TopNav from "../components/general/TopNav";
import HeroContact from "../components/contact/HeroContact";
import Footer from "../components/general/Footer";

function ContactUsPage() {
 
  return (
    <div>
      <TopNav />
      <HeroContact />
      <Footer />
    </div>
  );
}

export default ContactUsPage;
