import React from "react";
import OurStoryHeroPic from "../../assets/others/ourStoryHeroPic.webp";
import OurStoryPalm from "../../assets/others/ourStoryPalm.jpg";
import whyUsPalm from "../../assets/whyUs/whyUsPalm.jpg";

function OurStoryHero() {
  return (
    <div>
      <img src={OurStoryHeroPic} alt=''className='w-full h-[450px]'/>
      {/* <div className='h-[550px] w-full bg-green-950 pt-1'>
        <h1 className='font-bold text-[45px] ml-[350px] mt-[30px] text-white font-serif'>Oracleous Enterprises </h1>
        <div className='space-y-[50px] ml-[300px] mr-[500px] mt-[30px] grid-flow-row justify-end'>
        <h1 className='text-white'>Oracleous enterprise is a mid-stream player in the oil palm value chain<br/> jthat focuses on the processing and distribution of palm oil. <br/> The focus of Oracleous enterprise stems from the obvious increase in the consumption <br/>of palm oil and the apparent growth in the value and demand for high-quality palm oil across Nigeria<br/> Our target market includes both the palm oil retailers and corporate consumers in varios parts of the Nigeria and beyond.</h1>
        <h1 className='text-white'>Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel <br/>justo cursus, faucibus lorem eget, egestas <br/>eros. Maecenas eleifend erat at justo<br/> fringilla imperdiet id ac magna.Suspendisse <br/>vel facilisis odio, at ornare nibh. In malesuada,<br/> tortor eget sodales mollis, mauris lectus hendrerit purus, <br/>porttitor finibus eros lorem eget mauris.<br/> Curabitur lacinia enim at ex blandit,<br/> vel pellentesque odio elementum.</h1>
        </div>
       

      </div> */}
      {/* <div className='h-[550px] w-full bg-green-950 pt-1'>
    <h1 className='font-bold text-[45px] text-center mt-8 text-white font-serif'>Oracleous Enterprises</h1>
    <div className='max-w-screen-md mx-auto mt-8 space-y-12 text-center'>
        <h1 className='text-white'>
            Oracleous enterprise is a mid-stream player in the oil palm value chain<br/>
            that focuses on the processing and distribution of palm oil. <br/>
            The focus of Oracleous enterprise stems from the obvious increase in the consumption <br/>
            of palm oil and the apparent growth in the value and demand for high-quality palm oil across Nigeria.<br/>
            Our target market includes both the palm oil retailers and corporate consumers in various parts of Nigeria and beyond.
        </h1>
        <h1 className='text-white'>
            Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel <br/>
            justo cursus, faucibus lorem eget, egestas <br/>
            eros. Maecenas eleifend erat at justo<br/>
            fringilla imperdiet id ac magna. Suspendisse <br/>
            vel facilisis odio, at ornare nibh. In malesuada,<br/>
            tortor eget sodales mollis, mauris lectus hendrerit purus,<br/>
            porttitor finibus eros lorem eget mauris.<br/>
            Curabitur lacinia enim at ex blandit,<br/>
            vel pellentesque odio elementum.
        </h1>
    </div>
</div>


<div className="bg-green-950 pt-24 px-4  pb-10">
    <div className="flex flex-col md:flex-row justify-center gap-10">
        <h1 className="text-white text-center">
            Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel
            <br /> justo cursus, faucibus lorem eget, egestas
            <br /> eros. Maecenas eleifend erat at justo <br />
            fringilla imperdiet id ac magna. Suspendisse
            <br /> vel facilisis odio, at ornare nibh. In malesuada,
            <br /> tortor eget sodales mollis, mauris lectus hendrerit purus,
            <br /> porttitor finibus eros lorem eget mauris.
            <br /> Curabitur lacinia enim at ex blandit, <br />
            vel pellentesque odio elementum.
        </h1>
        <h1 className="text-white text-center">
            Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel{" "}
            <br />
            justo cursus, faucibus lorem eget, egestas <br />
            eros. Maecenas eleifend erat at justo
            <br /> fringilla imperdiet id ac magna. Suspendisse <br />
            vel facilisis odio, at ornare nibh. In malesuada,
            <br /> tortor eget sodales mollis, mauris lectus hendrerit purus,{" "}
            <br />
            porttitor finibus eros lorem eget mauris.
            <br /> Curabitur lacinia enim at ex blandit,
            <br /> vel pellentesque odio elementum.
        </h1>
    </div>
    <div className="h-[8px] w-[250px] mt-6 bg-lime-700 mx-auto"></div>
</div> */}
<div className='h-[550px] w-full bg-green-950 pt-1 overflow-hidden'>
    <h1 className='font-bold text-[45px] text-center mt-8 text-white font-serif'>Oracleous Enterprises</h1>
    <div className='max-w-screen-md mx-auto mt-8 space-y-6 text-center'>
        <h1 className='text-white text-sm md:text-base'>
            Oracleous enterprise is a mid-stream player in the oil palm value chain<br/>
            that focuses on the processing and distribution of palm oil. <br/>
            The focus of Oracleous enterprise stems from the obvious increase in the consumption <br/>
            of palm oil and the apparent growth in the value and demand for high-quality palm oil across Nigeria.<br/>
            Our target market includes both the palm oil retailers and corporate consumers in various parts of Nigeria and beyond.
        </h1>
        <h1 className='text-white text-sm md:text-base'>
            Oracleous enterprise is filling the demand and supply gap in the palm<br/>
            oil industry by aggregating the palm fruits from our network of farmers  <br/>
            in Anambra and Edo state, processing the palm kernel to crude palm oil (red oil),<br/>
            so as to meet the demand for palm oil in Kaduna state, Nigeria. <br/>
            Our network of farmers are majorly located in Edo state. By aggregating <br/>
            palm fruits from them we are able to currently produce 144,000 liters of palm <br/>
            oil per harvest season. The harvest season is between November to June<br/>
            The farmers also get to generate decent income from the sales of the palm fruits.<br/>
        </h1>
    </div>
    
</div>

<div className="bg-green-950 pt-18 px-4 pb-10">
    <div className="flex flex-col md:flex-row justify-center gap-8">
        <h1 className="text-white text-center mb-6 text-sm md:text-base">
            In a quaint village nestled among lush green palm trees, 
            <br /> Oracleous Enterprises began as a humble household business.
            <br /> It all started when a determined woman,<br />
            fueled by a passion for agriculture, noticed her community’s
            <br /> reliance on a popular cooking oil. She decided to take action,
            <br /> using traditional methods passed down through generations to create <br /> small batches by hand.
        </h1>
        <h1 className="text-white text-center mb-6 text-sm md:text-base">
        Every morning, she would rise with the sun, gathering fresh fruits from{" "}
            <br />
            towering trees, boiling them in large pots, and manually  <br />
            pressing them to extract the golden liquid.
            <br /> Family and friends often joined, turning the labor into a joyful community affair. <br />
            The inviting aroma spread through the air, attracting neighbors eager to buy
            <br /> the freshly made product. Word spread quickly about the quality,{" "}
            <br />
            and soon, demand began to outstrip the manual capabilities.
           </h1>
    </div>
    <div className="h-[8px] w-[250px] mt-6 bg-lime-700 mx-auto"></div>
</div>


      <div className="bg-purple-200 py-16 w-full mx-auto">
        <div className="bg-purple-100 w-full max-w-[1130px] pb-16 px-10 mx-auto">
          <h1 className="text-3xl pt-[80px] ml-[50px] text-center mb-10 font-bold font-serif">
          Recognizing the potential for growth, our founding fathers pooled their resources, and took a bold step.
          </h1>

          <div className="flex flex-col gap-10 md:flex-row">
            <div className="w-full text-center">
              <h3 className="text-8xl font-bold font-serif">58</h3>
              <p className="mt-[20px]">
                Years of
                <br /> Experience
              </p>
            </div>

            <div className="w-full">
              <p className="first-letter:font-semibold first-letter:text-5xl first-letter:text-lime-700">
              They invested in semi-automated machinery, including a screw presser and a digester. This new equipment
                  transformed the operation, allowing for larger quantities to be produced while maintaining the same high standards.
                With a small team, they worked tirelessly, expanding capacity and reaching out to new markets.
              </p>
              
            </div>
            <div className="w-full">
              <p className="md:mt-6">
              As their venture flourished, they also acquired a generator to ensure a steady power supply,
                <br /> enabling efficient operation of the machines. <br />
                Distribution expanded beyond the village, <br />
                securing contracts with local retailers and larger consumers. <br />
              </p>
            </div>
          </div>
        </div>

        <img
          src={OurStoryPalm}
          alt=""
          className="w-[1130px] mx-auto h-[450px]"
        />

        <div className="bg-purple-100 w-full px-10 max-w-[1130px] pb-16 mx-auto">
          <h1 className="text-3xl pt-[80px] text-center mb-10 font-bold font-serif">
          With each passing year, the initial vision transformed into a thriving enterprise. 
          They became known not just for their product, but for a commitment to sustainable practices 
          and community empowerment.
            
          </h1>

          <div className="flex flex-col gap-10 md:flex-row">
            
            <div className="w-full">
              <p style={{ initialLetter: 2 }} className="md:mt-6 first-letter:font-semibold first-letter:text-5xl first-letter:text-lime-700 first-letter:px-2">
              As the years went by, the impact of their efforts extended far beyond mere production. 
              Our Fathers actively engaged with the community, offering training and workshops to 
              local farmers on sustainable practices and modern techniques. This initiative not 
              only empowered their neighbors but also fostered a sense of unity and collaboration. 
              The once-small household endeavor blossomed into a movement, inspiring others to embrace 
              innovation while honoring traditional methods. Today, their legacy is not just measured in 
              the volume of oil produced, but in the vibrant community that has grown around them, all 
              thriving together in harmony with nature.
              </p>
            </div>
            <div className="w-full">
              <p
                style={{ initialLetter: 2 }}
                className="first-letter:font-semibold first-letter:text-5xl first-letter:text-lime-700 first-letter:px-2"
              >
                Today, this venture stands as a beacon of innovation and resilience, 
                illustrating how a small household endeavor can grow into a significant 
                force in the agricultural landscape. The founders often reflect on their 
                journey with gratitude, knowing that their story is woven into the very fabric 
                of their community. From manual labor to modern machinery, they continue to thrive, 
                driven by the same passion and dedication that sparked their beginnings.
                
              </p>
          
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-purple-100 h-[730px] w-full"></div> */}

      <div className="px-10 md:px-16 mt-10">
        <div className="flex flex-col md:flex-row md:justify-center gap">
          <div className="flex flex-col justify-center bg-green-950 lg:h-[450px] px-6 pb-6 md:px-10 md:w-[700px]">
            <h2 className="font-bold text-[28px] py-6 md:pt-[12/0px] text-purple-200 font-serif">
            African Oil Palm: Bridging Tradition and Modernity
            </h2>
            <p className="text-slate-200 text-[17px]">
              African oil palm is more widely distributed than other types of
              palm trees and was first discovered in Africa along the coastal
              strip. Since being domesticated, the oil palm has been imported
              and grown throughout the humid tropics, with the majority of palm
              oil being used for food. It still has numerous traditional
              applications in Africa. Breeding can increase the amount of oil
              produced by the palm, enhance the type of oil it produces, and
              improve nutritional value.
            </p>
          </div>
          <div className="order-first md:order-last">
            <img
              src={whyUsPalm}
              alt=""
              className="w-full h-full lg:h-[450px] md:w-[450px] mt-[-4/50px] ml-[8/00px] "
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurStoryHero
