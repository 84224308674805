import React from "react";
import RedPalm from "../../assets/ourExperience/Red-Palm-.jpg";
import PalmAgr from "../../assets/ourExperience/PalmAgr.jpg";
import shutter from "../../assets/ourExperience/shutterstock.webp";
import white from "../../assets/ourExperience/whitePalm.jpg";
import PalmPeople from "../../assets/ourExperience/PalmPeople.jpg";
import PalmHand from "../../assets/ourExperience/pamlHand.jpg";

function ourExperiences() {
  return (
    <div className="h-[200px] mt-[30px] w-full bg-slate-50">
      <div className="overflow-auto whitespace-nowrap mt-[50px]">
        <div className="flex  gap-[20px] animate-marquee">
          <img src={RedPalm} alt="palm" className="w-[250px] h-[250px] " />
          <img src={PalmAgr} alt="palm" className="w-[250px] h-[250px]" />
          <img src={shutter} alt="palm" className="w-[250px] h-[250px]" />
          <img src={white} alt="palm" className="w-[250px] h-[250px]" />
          <img src={PalmPeople} alt="palm" className="w-[250px] h-[250px]" />
          <img src={PalmHand} alt="palm" className="w-[250px] h-[250px]" />
        </div>
      </div>
    </div>
  );
}

export default ourExperiences;
