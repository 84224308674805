import React from "react";
import Farm2 from "../../assets/home/blurHero.webp";
import { HomeCardData } from "../../data/HomeCardData";
import palm from "../../assets/home/palmHero.png";

function HomeHero() {
  return (
    <div className="Farm2 mt-[75px]">
      <div
        className="bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${Farm2})` }}
      >
        <div className="bg-black/30 px-[50px] relative">
          <h1 className="text-5xl text-purple-100 pt-16 md:pt-[100px] lg:pt-[150px] lg:mr-[400px] content-center font-sans font-bold">
            Introducing Nigeria's Most Affordable and Undiluted Palm Oil
          </h1>
          <div className="h-[5px] w-[310px] bg-lime-400"></div>
          <div className="flex justify-between mt-10 pb-10 mb-10 items-center">
            <p className="text-purple-50 md:w-3/5">
              Oracleous Enterprises is dedicated to producing Nigeria's most
              affordable and undiluted palm oil. By harnessing cutting-edge
              technology and sustainable practices, we ensure high-quality
              products that meet both local and global demand.
            </p>
            <img
              src={palm}
              alt="Palm"
              className="hidden md:block ml-[5/57px] absolute bottom-0 right-0 w-3/5 mt-[25/0px]"
            />
          </div>
        </div>

        {/* <img
          src={Farm2}
          alt="Farm2"
          className="w-full h-[550px] filter brightness-30"
        /> */}
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-[40px] mt-[50px] px-10 md:px-16">
        {HomeCardData.map((data, index) => {
          return (
            <div
              key={index}
              className="bg-lime-900 lg:h-[500px] p-2 text-center w-[2/50px] rounded-lg"
            >
              <h3 className="font-bold text-2xl pt-[30px] text-purple-200 font-serif">
                {data.title}
              </h3>
              <img
                src={data.image}
                alt=""
                className="w-2/5 lg:w-[180px] lg:h-[180px] mx-auto mt-[80px]"
              />
              <p className="text-purple-100 mt-[30px]">
                {data.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HomeHero;
