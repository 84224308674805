import React from "react";
import Footer from "../general/Footer";
import productHeroPalm from "../../assets/product/tree.jpg";
import ProductKarnel from "../../assets/others/palm-oil.png";
import ProductPalm from "../../assets/others/Palm-Kernel.webp";
import ProductKarnel2 from "../../assets/others/Agric-consultancy.jpg";
import { ProductNumberData } from "../../data/ProductNumberData";

function ProductHero() {
  return (
    <div className="mt-[75px] bg-slate-200">
      <div
        className="bg-cover h-[350px]"
        style={{ backgroundImage: `url(${productHeroPalm})` }}
      >
        <div className=" flex items-center justify-center bg-black/30 w-full h-full">
          <h1 className="text-6xl font-bold text-white font-serif">Products</h1>
          
          
        </div>
      </div>

      <div className="h-[75/0px] w-full bg-g px-10 md:px-16">
        <div className="">
          <h1 className="text-xl text-lime-950 mt-[50px] font-serif">
            Our products include premium palm oil, palm kernel, and dedicated agro-consultancy services, all designed to enhance cultivation methods and promote sustainability. Together, they boost productivity while minimizing environmental impact for oil palm farmers.
          </h1>
        </div>
        {/* <div className="flex flex-col sm:flex-row gap-6 md:gap-[80px] py-16">
          {ProductIconData.map((data, index) => {
            return (
              <div
                key={index}
                className="flex flex-col gap-4 md:gap-10 justify-center items-center h-[500/px] md:w-[400px]"
              >
                <img
                  src={data.image}
                  alt=""
                  className="md:w-[150px] md:h-[150px] mt-[40px]"
                />
                <h1 className="font-bold text-2xl text-slate-200 font-serif">
                  {data.title}
                </h1>
                <h2 className="text-slate-100">{data.description}</h2>
              </div>
            );
          })}
        </div> */}
      </div>

      <div className="bg-slate-200 h-[2000/px] w-full px-10 space-y-14 md:px-16">
        <div className="flex flex-col md:flex-row gap-10 items-center py-10">
          <div className="w-full">
            <img
              src={ProductKarnel}
              alt=""
              className="h-[350px] w-[350px] mx-auto"
            />
          </div>
          <div className="w-full">
            <h2 className="text-5xl md:text-6xl font-serif font-bold pt-[150/px]">
              Palm oil
            </h2>
            <h3 className="text-[20px] font-bold my-[20px]">
              Is a vegetable oil from the oil palm tree, used in food, cosmetics, and biofuels<br />{" "}
            </h3>
            <p className="text-[15px]">
              Oracleous palm oil is unique for its rich fatty acids and antioxidants, making it a nutritious 
              <br /> choice for cooking. Its stability and vibrant color enhance culinary applications, <br />
              while its sustainable production supports local economies and traditional practices.
            </p>
            <div className="flex items-center justify-center h-[60px] mt-[30px] w-[200px] bg-lime-700 hover hover:bg-lime-600 hover:cursor-pointer">
              <span className="text-white">Read more</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-10 items-center py-10">
          <div className="order-first md:order-last w-full">
            <img
              src={ProductPalm}
              alt=""
              className="h-[350px] w-[350px] mx-auto"
            />
          </div>
          <div className="w-full">
            <h2 className="text-5xl md:text-6xl font-serif font-bold">Palm Kernel</h2>
            <h3 className="text-[20px] font-bold mt-[30px]">
            Palm kernel is the seed of the oil palm fruit, rich in oil and lauric acid, {" "}
            used to produce versatile palm kernel oil for food, cosmetics, and detergents.
            </h3>
            <p className="text-[15px] mt-[20px]">
            Oracleous Enterprises offers some of the finest palm kernel products, known for their high quality and rich oil content.
              <br />  Our palm kernels are sustainably sourced and processed, ensuring exceptional purity and versatility
              for various applications in food, cosmetics, and more. 
            </p>
            <div className="flex items-center justify-center h-[60px] mt-[30px] w-[200px] bg-lime-700 hover hover:bg-lime-600 hover:cursor-pointer">
              <span className="text-white">Read more</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-10 items-center py-10">
          <div className="w-full order-last md:order-first">
            <img
              src={ProductKarnel2}
              alt=""
              className="w-full md:h-[350px] md:w-[350px] mx-auto"
            />
          </div>
          <div className="w-full order-last md:order-first">
            <h2 className="text-5xl md:text-6xl font-serif font-bold">Consultancy</h2>
            <h3 className="text-[20px] font-bold my-[20px]">
            Our Agro-consultancy services provides expert advice to farmers on best practices in agriculture, including crop management, pest control, and sustainable techniques.  <br />{" "}
          
            </h3>
            <p className="text-[15px]">
            Oracleous' agro-consultancy arm is dedicated to supporting oil palm farmers and local producers by enhancing production techniques, promoting sustainable practices, and boosting overall productivity.
              <br />Our goal is to empower farmers for a more efficient and environmentally friendly agricultural future. <br />
              </p>
            <div className="flex items-center justify-center h-[60px] mt-[30px] w-[200px] bg-lime-700 hover hover:bg-lime-600 hover:cursor-pointer">
              <span className="text-white">Read more</span>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[800px] pb-16 mx-auto grid md:grid-cols-4 gap-6 md:gap-10 mt-[300px]">
          {ProductNumberData.map((data, index) => {
            return (
              <div key={index} className="">
                <div className="text-center space-y-[5px]">
                  <h1 className="font-bold text-3xl">{data.number}</h1>
                  <h1 className="font-bold text-lime-700 text-[17px] mt-[20px]">
                    {data.title}
                  </h1>
                  <h2 className="mt-[25px] ">{data.description}</h2>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full bg-purple-200">
        <div className="py-[10px]">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ProductHero;
