import React from 'react'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import HomePage from './pages/HomePage'
import AboutUsPage from './pages/AboutUsPage';
import ProductPage from './pages/ProductPage'
import ContactUsPage from './pages/ContactUsPage';
import OurStoryPage from './pages/OurStoryPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/about-us-page" element={<AboutUsPage/>} />
        <Route path="/product-page" element={<ProductPage/>} />
        <Route path="/our-story-page" element={<OurStoryPage/>} />
        <Route path="/contact-page" element={<ContactUsPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
