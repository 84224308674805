import agricultural from '../assets/icons/agricultural.png'
import agriculture from '../assets/icons/agriculture.png'
import crisis from '../assets/icons/crisis.png'
import economy from '../assets/icons/economy.png'
import partnership from '../assets/icons/partnership.png'
import tractor from '../assets/icons/tractor.png'

export const WhyUsData = [
    {
        title: 'Our Value Proposition',
        image : agricultural,
        description: 'We produce high-quality, sustainably sourced palm oil that supports local communities and protects the environment.'
    },
    {
        title: 'Energy Efficiant Techniques',
        image : agriculture,
        description: 'We implement energy-efficient techniques in our production process, optimizing resource use while minimizing environmental impact.'
    },
    {
        title: 'Undiluted Palm Oil',
        image :crisis ,
        description: 'Our palm oil is undiluted and 100% pure, ensuring the highest quality and maximum nutritional value for our customers. This commitment to purity supports sustainable practices and enhances the integrity of our products.'
    },
    {
        title: 'Our Income Potential',
        image :economy ,
        description: 'We have a robost income potential, driven by a combination of sustainable practices, high-quality production, and a growing demand for eco-friendly products in the market..'
    },
    {
        title: 'Valued Partnerships',
        image:  partnership,
        description: 'We have established valued partnerships that enhance our capabilities, foster innovation, and strengthen our market presence, enabling us to deliver greater value to our customers and stakeholders.'
    },
    {
        title: 'Efficient Delivery',
        image: tractor,
        description: 'We deliver our palm oil efficiently, ensuring timely distribution without compromising quality. Our streamlined logistics allow us to meet customer demands promptly while maintaining sustainability standards.'
    }
]