export const ProductNumberData=[
    {
        title: 'Gallons Produced',
        number : '10,000+',
        // description: 'Aenean et sodales est nibh vestibulum lorem'
    },
    {
        title: 'Partnerships',
        number :100,
        // description: 'Aenean et sodales est nibh vestibulum lorem'
    },
    {
        title: 'Success Rate',
        number : '85%',
        // description: 'Aenean et sodales est nibh vestibulum lorem'
    },
    {
        title: 'Generations',
        number :4 ,
        // description: 'Aenean et sodales est nibh vestibulum lorem'
    }
]