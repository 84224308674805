import React from 'react'
import TopNav from '../components/general/TopNav'
import ProductHero from '../components/our-product/ProductHero'

function ProductPage() {
  
  return (
    <div>
      <TopNav/>
      <ProductHero/>
    </div>
  )
}

export default ProductPage;
