import React from "react";
import oracleous from "../../assets/logo/OracleousLogo.png"

function Footer() {
  return (
    <>
      <footer className="mt-10 md:mt-16 px-10 md:px-16">
        <div className="flex flex-col md:flex-row gap-10 justify-between">
          <div className="ml-4 md:ml-0 mt-[-10px]">
            <img src={oracleous} alt="logo" />
          </div>
          {/* contact us */}
          <div className="">
            <h5 className="text-[18px] font-serif">
              <span className="font-bold">Contact us</span>
              <br />
              Contact us to receive full details and 
              <br /> learn more about our products and services!
            </h5>
            <a href="/contact-page"><div className="h-[50px] w-[160px] mt-[30px] bg-lime-800 hover:bg-lime-600 hover:cursor-pointer ">
              <h1 className="ml-[40px] pt-[10px] text-slate-200">Read more</h1>
            </div>
            </a>

            <div className="flex space-x-[25px] mt-[30px] text-lime-800">
              <a href="/contact-page" className="hover:underline">
                FAQ
              </a>
              <a href="/contact-page" className="hover:underline">
                Support
              </a>
              <a href="/contact-page" className="hover:underline">
                Useful info
              </a>
            </div>
          </div>

          {/* div 2 */}
          {/* mission statement */}
          <div className="">
            <h5 className="text-[18px] font-bold">Our mission</h5>
            <h1 className="">
              To modernize palm oil processing by  
              <br /> introducing efficient processing techniques,<br /> increasing production while
              <br /> maintaining sustainable practices
            </h1>
            <div className="mt-[10px] text-lime-800 hover:underline">
              <a href="/about-us-page">Read more</a>
            </div>
          </div>
        </div>
      </footer>
      <div className="text-center mt-[100px] mb-6 text-[15px] font-bold">
        <h1 className="ml-[550p/x]">
          Powered by{" "}
          <a
            href="/"
            className="text-lime-800 hover:underline"
          >
            Spring Core Africa{" "}
          </a>
        </h1>
      </div>
    </>
  );
}

export default Footer;
